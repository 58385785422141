import React from 'react';
import DocumentPage, { DocumentPageContext } from '../components/DocumentPage';
import WorkPlanCard from '../components/WorkPlanCard';
import { WorkPlanBM } from '@bm-js/h2o-shared';
import { PageProps } from 'gatsby';

const context: DocumentPageContext<WorkPlanBM> = {
  documentName: 'Arbetsmiljöplan',
  documentNameSpecific: 'Arbetsmiljöplan',
  documentKey: 'workplan',
  documentKeyPlural: 'workplans',
  documentNamePlural: 'Arbetsmiljöplaner',
  internalPersonKey: 'technicians',
  cardComponent: WorkPlanCard,
  internalPersonKeyRequired: false,
  cardGridTemplate: '2fr 2fr 2fr 2fr 2fr 20px',
  cardHeaders: [
    {
      text: 'Upprättades',
      mobile: true,
    },
    {
      text: 'Startdatum',
    },
    {
      text: 'Slutdatum',
    },
    {
      text: 'Reservoar',
      mobile: true,
    },
    {
      text: 'Tekniker',
    },
  ],
};

const WorkPlansPage = ({ location }: PageProps) => {
  const props = { location, context };
  return <DocumentPage {...props} />;
};

export default WorkPlansPage;
