import React from 'react';
import { parseDate } from '../utils/parseDate';
import Button from './Button';
import Card from './Card';
import DotsDropdown from './DotsDropdown';
import { WorkPlanBM } from '@bm-js/h2o-shared';

type Props = {
  entry: WorkPlanBM;
  deleteEntryInit: (entry: WorkPlanBM) => void;
  editEntryInit: (entry: WorkPlanBM) => void;
  sendEntryInit: (entry: WorkPlanBM) => void;
  downloadPdf: (entry: WorkPlanBM) => void;
  previewPdf: (entry: WorkPlanBM) => void;
  duplicateEntry: (entry: WorkPlanBM) => void;
};

const WorkPlanCard = ({
  entry,
  deleteEntryInit,
  editEntryInit,
  sendEntryInit,
  downloadPdf,
  previewPdf,
  duplicateEntry,
}: Props) => {
  return (
    <div>
      <Card className="order-grid">
        <p className="mobile">{parseDate(entry.creationDate)}</p>
        <p>{parseDate(entry.startDate)}</p>
        <p>{parseDate(entry.endDate)}</p>
        <p className="mobile">{entry.reservoir?.name}</p>
        <p>
          {entry.technicians.map((technician, i) => (
            <React.Fragment
              key={`workorder_technichian_${technician._id}_${i}`}
            >
              {technician.firstName} {technician.lastName}
              <br />
            </React.Fragment>
          ))}
        </p>
        <DotsDropdown
          content={
            <>
              <Button small onClick={() => previewPdf(entry)}>
                Förhandsvisa
              </Button>
              <Button small onClick={() => downloadPdf(entry)}>
                Ladda ned
              </Button>
              <Button small onClick={() => editEntryInit(entry)}>
                Redigera
              </Button>
              <Button small onClick={() => duplicateEntry(entry)}>
                Duplicera
              </Button>
              <Button small onClick={() => sendEntryInit(entry)}>
                Skicka som e-post
              </Button>
              <Button
                small
                onClick={() => deleteEntryInit(entry)}
                look="danger"
              >
                Radera
              </Button>
            </>
          }
        />
      </Card>
    </div>
  );
};

export default WorkPlanCard;
